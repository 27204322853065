import {
  ColumnTypeV1,
  DatasetDetailsPageInfoProps,
  DatasetFieldTypesPropsV1,
  DataTypeV1,
} from '@services/datasetApi'
import { MAX_NUM_DEFAULT_COLUMNS } from '@utils/ag-grid/consts'
import { ColDef, ICellRendererParams } from 'ag-grid-community'
import { GridOptions } from 'ag-grid-community/dist/lib/entities/gridOptions'

import DatasetTableHeader from '../datasetTableHeader/DatasetTableHeader'
import DatasetTableCellRenderer from './datasetTableCellRenderer/DatasetTableCellRenderer'
import ImageCellRenderer from './imageCellRenderer/ImageCellRenderer'

export const constructDatasetGridOptions = (
  datasetDetails: DatasetDetailsPageInfoProps,
  fieldTypes?: DatasetFieldTypesPropsV1,
  isFullyEnriched?: boolean
): GridOptions => {
  const columnDefs = constructColumnDefs(datasetDetails, fieldTypes, isFullyEnriched)
  const showingImages = datasetDetails.modality === 'image'
  return {
    defaultColDef: {
      filter: true,
      sortable: true,
      resizable: true,
      flex: 1,
      width: !showingImages ? 100 : undefined,
      minWidth: 150,
      cellStyle: {
        display: 'flex',
        alignItems: 'center',
      },
    },
    columnDefs: columnDefs,
    rowHeight: showingImages ? 160 : undefined,
    rowModelType: 'serverSide',
    suppressServerSideInfiniteScroll: false,
    cacheBlockSize: 100, // fetch this number of rows at a time
    maxBlocksInCache: 1, // only keep 1 block of rows in cache
    animateRows: true,
    getContextMenuItems: (params) => {
      if (params.value === undefined) {
        return []
      } else {
        return ['copy']
      }
    },
    suppressFieldDotNotation: true,
    alwaysShowHorizontalScroll: true,
  }
}

const constructColumnDefs = (
  datasetDetails: DatasetDetailsPageInfoProps,
  fieldTypes?: DatasetFieldTypesPropsV1,
  isFullyEnriched?: boolean
) => {
  const idColumn = datasetDetails.id_column
  const datasetColumns = datasetDetails.columns.filter((col) => col !== idColumn)

  const idColumnDef = getColumnDef(datasetDetails, fieldTypes, idColumn)

  const datasetColumnDefs = datasetColumns.map((col, index) => {
    const colDef = getColumnDef(datasetDetails, fieldTypes, col)
    if (index > MAX_NUM_DEFAULT_COLUMNS) {
      colDef.hide = true
    }
    return colDef
  })

  const imageColumnDefs =
    datasetDetails.media_url_columns?.map((col) => {
      const colDef = getColumnDef(datasetDetails, fieldTypes, col)
      return colDef
    }) ?? []

  const enrichmentColumnDefs =
    datasetDetails.enrichment_columns?.map((col) => {
      const colDef = getColumnDef(datasetDetails, fieldTypes, col, true, isFullyEnriched)
      return colDef
    }) ?? []

  return [idColumnDef, ...imageColumnDefs, ...enrichmentColumnDefs, ...datasetColumnDefs]
}

const getColumnDef = (
  datasetDetails: DatasetDetailsPageInfoProps,
  fieldTypes: DatasetFieldTypesPropsV1 | undefined,
  column: string,
  isEnrichmentColumn?: boolean,
  isFullyEnriched?: boolean
): ColDef => {
  const idColumn = datasetDetails.id_column
  const baseDef = {
    field: column,
    filter: true,
    sortable: true,
    resizable: true,
    flex: 1,
    cellRenderer: DatasetTableCellRenderer,
  }
  if (column === idColumn) {
    return {
      hide: column === 'cleanlab_row_ID',
      field: idColumn,
      filter: 'agTextColumnFilter',
      filterParams: {
        suppressAndOrCondition: true,
      },
      pinned: 'left',
      headerComponent: DatasetTableHeader,
      headerComponentParams: {
        columnType: fieldTypes?.[idColumn]?.column_type,
        dataType: fieldTypes?.[idColumn]?.data_type,
        mutable: fieldTypes?.[idColumn]?.mutable,
      },
      headerClass: 'dataset-column',
      headerName: column,
      autoHeaderHeight: true,
    }
  } else if (column.startsWith('_cleanlab_media_url')) {
    return {
      field: '_cleanlab_media_url',
      headerComponent: DatasetTableHeader,
      headerComponentParams: {
        columnType: ColumnTypeV1.Image,
        dataType: DataTypeV1.String,
        mutable: false,
        displayName: 'Image',
        disableSort: true,
      },
      headerClass: 'dataset-column',
      sortable: false,
      cellRenderer: (params: ICellRendererParams) => ImageCellRenderer(column, params),
      minWidth: 200,
      filter: false,
      headerName: column,
    }
  } else if (isEnrichmentColumn) {
    const isTrustworthinessScoreColumn = column.includes('_trustworthiness_score')

    return {
      ...baseDef,
      field: column,
      headerName: column,
      pinned: 'right',
      filter: false,
      sortable: isTrustworthinessScoreColumn && isFullyEnriched,
      width: isTrustworthinessScoreColumn ? 120 : 400,
      minWidth: isTrustworthinessScoreColumn ? 120 : 300,
      headerValueGetter: () => (isTrustworthinessScoreColumn ? 'Trust Score' : column),
      wrapText: !isTrustworthinessScoreColumn,
      autoHeight: true,
      headerClass: 'datasetGrid_enrichmentHeader',
    }
  } else {
    return {
      ...baseDef,
      field: column,
      headerComponent: DatasetTableHeader,
      headerComponentParams: {
        columnType: fieldTypes?.[idColumn]?.column_type,
        dataType: fieldTypes?.[idColumn]?.data_type,
        mutable: fieldTypes?.[idColumn]?.mutable,
      },
      headerClass: 'dataset-column',
      autoHeaderHeight: true,
      headerName: column,
    }
  }
}
