import {
  FiActivity,
  FiAlertOctagon,
  FiAlertTriangle,
  FiAnchor,
  FiArrowDown,
  FiArrowLeft,
  FiArrowRight,
  FiArrowUp,
  FiBarChart,
  FiBook,
  FiCheck,
  FiChevronLeft,
  FiChevronRight,
  FiClock,
  FiCode,
  FiCopy,
  FiDatabase,
  FiEdit3,
  FiExternalLink,
  FiEye,
  FiEyeOff,
  FiFile,
  FiFileText,
  FiGlobe,
  FiGrid,
  FiHelpCircle,
  FiImage,
  FiInfo,
  FiLifeBuoy,
  FiLink,
  FiMail,
  FiMenu,
  FiMoon,
  FiMoreHorizontal,
  FiPlay,
  FiPlus,
  FiRefreshCw,
  FiSearch,
  FiSlack,
  FiSun,
  FiTarget,
  FiTerminal,
  FiTrash,
  FiUpload,
  FiUploadCloud,
  FiX,
  FiYoutube,
  FiZap,
  FiZapOff,
} from 'react-icons/fi'
import { LuGraduationCap } from 'react-icons/lu'

import { wrapFeatherIcon } from './wrapFeatherIcon'

export const IconActivity = wrapFeatherIcon(FiActivity)
export const IconAnchor = wrapFeatherIcon(FiAnchor)
export const IconArrowDown = wrapFeatherIcon(FiArrowDown)
export const IconArrowLeft = wrapFeatherIcon(FiArrowLeft)
export const IconArrowRight = wrapFeatherIcon(FiArrowRight)
export const IconArrowUp = wrapFeatherIcon(FiArrowUp)
export const IconBarChart = wrapFeatherIcon(FiBarChart)
export const IconBook = wrapFeatherIcon(FiBook)
export const IconCaution = wrapFeatherIcon(FiAlertTriangle)
export const IconCheck = wrapFeatherIcon(FiCheck)
export const IconChevronLeft = wrapFeatherIcon(FiChevronLeft)
export const IconChevronRight = wrapFeatherIcon(FiChevronRight)
export const IconClock = wrapFeatherIcon(FiClock)
export const IconCode = wrapFeatherIcon(FiCode)
export const IconCopy = wrapFeatherIcon(FiCopy)
export const IconDatabase = wrapFeatherIcon(FiDatabase)
export const IconEdit = wrapFeatherIcon(FiEdit3)
export const IconExternalLink = wrapFeatherIcon(FiExternalLink)
export const IconEye = wrapFeatherIcon(FiEye)
export const IconEyeOff = wrapFeatherIcon(FiEyeOff)
export const IconFile = wrapFeatherIcon(FiFile)
export const IconFileText = wrapFeatherIcon(FiFileText)
export const IconGlobe = wrapFeatherIcon(FiGlobe)
export const IconGraduationCap = wrapFeatherIcon(LuGraduationCap)
export const IconGrid = wrapFeatherIcon(FiGrid)
export const IconHelpCircle = wrapFeatherIcon(FiHelpCircle)
export const IconImage = wrapFeatherIcon(FiImage)
export const IconInfo = wrapFeatherIcon(FiInfo)
export const IconLifeBuoy = wrapFeatherIcon(FiLifeBuoy)
export const IconLink = wrapFeatherIcon(FiLink)
export const IconMail = wrapFeatherIcon(FiMail)
export const IconMenu = wrapFeatherIcon(FiMenu)
export const IconMoon = wrapFeatherIcon(FiMoon)
export const IconMoreHorizontal = wrapFeatherIcon(FiMoreHorizontal)
export const IconPlay = wrapFeatherIcon(FiPlay)
export const IconPlus = wrapFeatherIcon(FiPlus)
export const IconRefresh = wrapFeatherIcon(FiRefreshCw)
export const IconSearch = wrapFeatherIcon(FiSearch)
export const IconSlackLogo = wrapFeatherIcon(FiSlack)
export const IconSun = wrapFeatherIcon(FiSun)
export const IconTarget = wrapFeatherIcon(FiTarget)
export const IconTerminal = wrapFeatherIcon(FiTerminal)
export const IconTrash = wrapFeatherIcon(FiTrash)
export const IconUpload = wrapFeatherIcon(FiUpload)
export const IconUploadCloud = wrapFeatherIcon(FiUploadCloud)
export const IconWarning = wrapFeatherIcon(FiAlertOctagon)
export const IconX = wrapFeatherIcon(FiX)
export const IconYouTubeLogo = wrapFeatherIcon(FiYoutube)
export const IconZap = wrapFeatherIcon(FiZap)
export const IconZapOff = wrapFeatherIcon(FiZapOff)

export { IconCheckCircleFilled } from './IconCheckCircleFilled'
export { IconDatabricksLogo } from './IconDatabricksLogo'
export { IconExpand } from './IconExpand'
export { IconMessageCircleQuestion } from './IconMessageCircleQuestion'
export { IconSnowflakeLogo } from './IconSnowflakeLogo'
