import { DownloadIcon } from '@chakra-ui/icons'
import { Container, useToast, VStack } from '@chakra-ui/react'
import Layout from '@common/layout/Layout'
import Loading from '@common/layout/loading/Loading'
import { Button } from '@components/button/Button'
import PageTitle from '@components/pageTitle/PageTitle'
import { Tooltip } from '@components/tooltip/Tooltip'
import { useSubscription } from '@providers/billing/SubscriptionProvider'
import { notifyAxiosError } from '@providers/errors/ErrorToast'
import NonExistentResource from '@providers/errors/NonExistentResource'
import datasetApiService, { DatasetDetailsPageInfoProps } from '@services/datasetApi'
import { EnrichmentJobType } from '@services/enrichment/constants'
import {
  useEnrichmentJobPreview,
  useEnrichmentJobs,
  useEnrichmentProject,
} from '@services/enrichment/queries'
import { enrichmentJobExportToDownloadableFile } from '@services/enrichmentApi'
import { checkFeatureFlag } from '@utils/functions/checkFeatureFlag'
import { GridApi } from 'ag-grid-community'
import { AxiosError } from 'axios'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import DatasetRowsLimitFloater from '../datasetDetails/datasetRowsLimitFloater/DatasetRowsLimitFloater'
import DatasetTableDetails from '../datasetDetails/datasetTableDetails/DatasetTableDetails'
import { SchemaUpdatesProvider } from '../datasetDetails/SchemaUpdatesContext'
import EnrichmentSidebar from './enrichmentSidebar/EnrichmentSidebar'
import EnrichmentStatusModal from './EnrichmentStatusModal'

export const ENRICHMENT_JOB_POLL_INTERVAL = 7500

const EnrichmentProject = () => {
  const { projectId = '' } = useParams<{ projectId: string }>()
  const [datasetPageDetails, setDatasetPageDetails] = useState<DatasetDetailsPageInfoProps>()
  const [gridApi, setGridApi] = useState<null | GridApi>(null)
  const toast = useToast()

  const { data: projectDetails } = useEnrichmentProject(projectId)
  const { datasetId, datasetName } = projectDetails ?? { datasetId: '' }
  const { data: projectJobs } = useEnrichmentJobs(projectId, {
    enabled: !!projectId,
    refetchInterval: (data) => {
      const mostRecentJob = data?.[0]

      return mostRecentJob?.status === 'IN_PROGRESS' ||
        mostRecentJob?.status === 'CREATED' ||
        mostRecentJob?.status === 'RUNNING'
        ? ENRICHMENT_JOB_POLL_INTERVAL
        : false
    },
  }) ?? { data: [] }
  const mostRecentJob = projectJobs?.[0]
  const {
    id: mostRecentJobId,
    type: mostRecentJobType,
    newColumnName,
    prompt,
    status: mostRecentJobStatus,
    constrainOutputs,
  } = mostRecentJob ?? {}
  const isFullyEnriched = mostRecentJobType === EnrichmentJobType.ENRICHMENT
  const { data: generatedRowsFromMostRecentJobDetails, refetch: refetchEnrichmentJobPreview } =
    useEnrichmentJobPreview(mostRecentJobId!, newColumnName!, {
      enabled: !!mostRecentJobId && !isFullyEnriched,
    }) ?? { data: [] }
  const { freeTrialPeriod, freeTrialDatasetRowsLimit, datasetRowsLimit } = useSubscription()
  const shouldShowStatusModal =
    mostRecentJobStatus === 'CREATED' || mostRecentJobStatus === 'RUNNING'
  const isExportable =
    mostRecentJobId !== undefined &&
    mostRecentJobType === EnrichmentJobType.ENRICHMENT &&
    mostRecentJobStatus === 'SUCCEEDED'
  const projectColumnsForSidebar = useMemo(
    () => datasetPageDetails?.columns.filter((col) => col !== datasetPageDetails.id_column),
    [datasetPageDetails]
  )
  const navigate = useNavigate()

  const fetchDatasetDetails = useCallback(
    async (datasetId: string) => {
      try {
        const datasetDetailsResponse = await datasetApiService.getDatasetDetailsPageInfo(datasetId)

        if (newColumnName) {
          datasetDetailsResponse.enrichment_columns = [
            newColumnName,
            `${newColumnName}_trustworthiness_score`,
          ]
        }

        setDatasetPageDetails(datasetDetailsResponse)
      } catch (err) {
        const statusCode = (err as AxiosError).response?.status
        if (statusCode && statusCode >= 400 && statusCode < 500) {
          // TBD
        } else {
          notifyAxiosError(toast, err as AxiosError, { title: 'Dataset fetch failed' })
        }
      }
    },
    [newColumnName, toast]
  )

  useEffect(() => {
    if (datasetId && projectJobs) {
      void fetchDatasetDetails(datasetId)
    }
    if (mostRecentJobId && !isFullyEnriched) {
      refetchEnrichmentJobPreview()
    }
  }, [
    datasetId,
    fetchDatasetDetails,
    mostRecentJobId,
    isFullyEnriched,
    refetchEnrichmentJobPreview,
    projectJobs,
  ])

  useEffect(() => {
    if (shouldShowStatusModal) {
      gridApi?.showLoadingOverlay()
    } else {
      gridApi?.hideOverlay()
    }
  }, [gridApi, shouldShowStatusModal])

  if (!checkFeatureFlag('ENRICHMENT_UI_ENABLED')) {
    navigate('/')
  }

  if (projectId === undefined) {
    return (
      <Layout>
        <NonExistentResource errorMessage="No such Project exists." />
      </Layout>
    )
  }
  if (!datasetPageDetails) {
    return (
      <Layout>
        <Loading text="Loading Dataset..." />
      </Layout>
    )
  }

  return (
    <>
      <Layout>
        <Container maxW="100%" maxH="99vh">
          <SchemaUpdatesProvider>
            <VStack>
              {datasetPageDetails.exceeds_rows_limit && (
                <DatasetRowsLimitFloater
                  freeTrialPeriod={freeTrialPeriod}
                  datasetRowsLimit={freeTrialPeriod ? freeTrialDatasetRowsLimit : datasetRowsLimit}
                />
              )}
              <div className="flex w-full flex-col items-center justify-between gap-5 pb-5 sm:max-lg:items-start lg:flex-row">
                <PageTitle>
                  {mostRecentJobType ? 'Viewing' : 'Enriching'}
                  &nbsp;<code className="bg-surface-2 p-4">{datasetName}</code>
                  <p className="type-body-300 mt-8">
                    {isFullyEnriched && 'Data successfully generated for all rows'}
                    {!isFullyEnriched && 'Test run completed'}
                  </p>
                </PageTitle>

                <Tooltip
                  disabled={isExportable}
                  content="Please generate data for all rows to enable exporting"
                  placement="bottom"
                >
                  <Button
                    onClick={() => enrichmentJobExportToDownloadableFile(mostRecentJobId!)}
                    disabled={!isExportable}
                    iconEnd={<DownloadIcon />}
                  >
                    Export data
                  </Button>
                </Tooltip>
              </div>
              <section className="flex h-full w-full gap-8">
                <EnrichmentSidebar
                  projectColumns={projectColumnsForSidebar}
                  projectId={projectId}
                  newColumnName={newColumnName}
                  prompt={prompt}
                  gridApi={gridApi}
                  mostRecentJobStatus={mostRecentJobStatus}
                  mostRecentJobType={mostRecentJobType}
                  constrainOutputs={constrainOutputs}
                />

                <div className="flex-grow">
                  {datasetPageDetails && (
                    <DatasetTableDetails
                      mostRecentJobId={mostRecentJobId}
                      enrichmentData={generatedRowsFromMostRecentJobDetails}
                      isFullyEnriched={isFullyEnriched}
                      datasetId={datasetId}
                      datasetDetails={datasetPageDetails}
                      refreshData={fetchDatasetDetails}
                      showDataWarnings={false}
                      showDatasetRowsLimit={datasetPageDetails.exceeds_rows_limit}
                      setGridApi={setGridApi}
                    />
                  )}
                </div>
              </section>
            </VStack>
          </SchemaUpdatesProvider>
        </Container>
      </Layout>

      <EnrichmentStatusModal
        isOpen={shouldShowStatusModal}
        jobId={mostRecentJobId}
        jobType={mostRecentJobType}
      />
    </>
  )
}

export default EnrichmentProject
