import { FEATURE_FLAGS_AS_ARRAY } from '@services/featureFlags/constants'

export enum DatasheetColumnType {
  CUSTOM = 'custom',
  ISSUE = 'issue',
  SCORE = 'score',
}

export interface DatasheetColumnConfig {
  featureFlag?: (typeof FEATURE_FLAGS_AS_ARRAY)[number]
  isInitiallyHidden: boolean
  headerName: string
  tooltip?: string
  columnType: DatasheetColumnType
  backendColumnName?: string
  isNumberColumn?: boolean
  tooltipLabel?: string
}
