import { Checkbox } from '@chakra-ui/react'
import Layout from '@common/layout/Layout'
import { Button } from '@components/button/Button'
import PageTitle from '@components/pageTitle/PageTitle'
import { FEATURE_FLAGS_AS_ARRAY } from '@services/featureFlags/constants'
import { FEATURE_FLAG_COOKIE_NAME, IS_LOCAL_ENV } from '@utils/environmentVariables'
import { FEATURE_FLAG_DELIMINATOR, getCookie } from '@utils/functions/checkFeatureFlag'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

const returnFlagsAsTuples = () => {
  const flagsFromCookie = getCookie(FEATURE_FLAG_COOKIE_NAME) ?? ''
  const splitFlagsFromCookie = flagsFromCookie.split(FEATURE_FLAG_DELIMINATOR)
  const mergedCookieAndConstFlags = FEATURE_FLAGS_AS_ARRAY.sort().map((flag): [string, boolean] => [
    flag,
    splitFlagsFromCookie.includes(flag),
  ])

  return mergedCookieAndConstFlags
}

const Flags = () => {
  const [isButtonDisabled, setIsButtonDisabled] = useState(true)
  const [flags, setFlags] = useState(returnFlagsAsTuples())
  const navigate = useNavigate()

  const handleFlagChange = (flag: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsButtonDisabled(false)
    setFlags((prevFlags) => {
      const newFlags = [...prevFlags]
      newFlags[newFlags.findIndex(([f]) => f === flag)][1] = e.target.checked
      return newFlags
    })
  }

  const handleSetAllFlags = (value: boolean) => () => {
    setIsButtonDisabled(false)
    setFlags((prevFlags) => prevFlags.map(([flag]) => [flag, value]))
  }

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    const flagsString = flags
      .filter(([_, value]) => value === true)
      .map(([flag]) => flag)
      .join(FEATURE_FLAG_DELIMINATOR)

    document.cookie = `${FEATURE_FLAG_COOKIE_NAME}=${flagsString};path=/;max-age=31536000` // 1 year expiry

    navigate('/')
  }

  useEffect(() => {
    if (!IS_LOCAL_ENV) {
      navigate('/')
    }
  }, [navigate])

  return (
    <Layout>
      <form onSubmit={onSubmit} className="mx-auto flex max-w-sm flex-col items-start gap-8">
        <PageTitle>Feature Flags</PageTitle>

        <div className="flex flex-col items-start gap-2">
          {flags.map(([flag, value]) => (
            <Checkbox
              key={flag}
              isChecked={value === true}
              onChange={handleFlagChange(flag)}
              name={flag}
            >
              {flag}
            </Checkbox>
          ))}
        </div>

        <div className="flex gap-4">
          <Button variant="primary" type="submit" disabled={isButtonDisabled} name="save">
            Save & exit
          </Button>

          <Button
            variant="primaryFaint"
            onClick={handleSetAllFlags(true)}
            type="button"
            name="enable all"
          >
            Enable all
          </Button>

          <Button
            variant="primaryFaint"
            onClick={handleSetAllFlags(false)}
            type="button"
            name="disable all"
          >
            Disable all
          </Button>
        </div>
      </form>
    </Layout>
  )
}

export default Flags
