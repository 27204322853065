const ErrorPage = ({ title, description }: { title?: string; description?: string }) => {
  return (
    <section className="flex min-h-[inherit] flex-col items-center justify-center gap-6">
      <h1 className="type-display-100">{title || 'Something went wrong'}</h1>
      <p className="type-body-200">
        {description || 'Please contact support@cleanlab.ai if the problem persists.'}
      </p>
    </section>
  )
}

export default ErrorPage
