import { FEATURE_FLAGS_AS_ARRAY } from '@services/featureFlags/constants'

import { FEATURE_FLAG_COOKIE_NAME } from '../environmentVariables'
export const FEATURE_FLAG_DELIMINATOR = '|'

export function getCookie(name: string) {
  const value = `; ${document.cookie}`
  const parts = value.split(`; ${name}=`)
  if (parts.length === 2) return (parts.pop() ?? '').split(';').shift()
}

// Initialize feature flags for local dev environment
export const initFeatureFlagsForLocalDev = () => {
  const cookie = getCookie(FEATURE_FLAG_COOKIE_NAME)

  if (!cookie) {
    // Will not override other cookies because of document.cookie's peculiar API
    document.cookie = `${FEATURE_FLAG_COOKIE_NAME}=${FEATURE_FLAGS_AS_ARRAY.join(FEATURE_FLAG_DELIMINATOR)}; path=/`
  }
}

export const checkFeatureFlag = (flag_name: (typeof FEATURE_FLAGS_AS_ARRAY)[number]): boolean => {
  const cookie = getCookie(FEATURE_FLAG_COOKIE_NAME)
  const flag_index = cookie?.split(FEATURE_FLAG_DELIMINATOR).indexOf(flag_name)
  return flag_index !== undefined ? flag_index !== -1 : false
}
