import { Box, Text, useToast } from '@chakra-ui/react'
import { defaultToastAlertProps } from '@common/alerts/defaultToastProps'
import SalesModal from '@common/billing/salesModal/SalesModal'
import UpgradePlanModal from '@common/billing/upgradePlanModal/UpgradePlanModal'
import ActivateFreeTrialPage from '@common/layout/activateFreeTrialPage/ActivateFreeTrialPage'
import AwaitingAdminInvitationPage from '@common/layout/awaitingAdminInvitationPage/AwaitingAdminInvitationPage'
import { LayoutProps, LayoutWrapperProps } from '@common/layout/Layout.types'
import Loading from '@common/layout/loading/Loading'
import Link from '@components/link/Link'
import { renderChakraToastAlert } from '@components/toast/ToastAlert'
import { useAuth } from '@hooks/useAuth'
import { useCleanlabLocation } from '@hooks/useCleanlabLocation'
import { useSubscription } from '@providers/billing/SubscriptionProvider'
import * as Sentry from '@sentry/react'
import { queryKeys } from '@services/user/constants'
import {
  useActivateAccountMutation,
  useActivateMutation,
  useConfirmEmail,
} from '@services/user/mutations'
import { useMyself } from '@services/user/queries'
import { IS_PROD_ENV, IS_VPC_ENVIRONMENT } from '@utils/environmentVariables'
import { useEffect } from 'react'
import Confetti from 'react-confetti'
import { useQueryClient } from 'react-query'
import ErrorPage from 'src/pages/errorPage/ErrorPage'
import Landing from 'src/pages/landing/Landing'

import NotificationBanner from './notificationBanner/NotificationBanner'
import TopNav from './topNav/TopNav'

const LayoutWrapper = (props: LayoutWrapperProps) => {
  const { nav, children, colorModeSwitcherDisabled = false } = props
  const { isAuthenticated } = useAuth()
  const { error: myselfError } = useMyself()
  const {
    isSalesModalOpen,
    onSalesModalClose,
    isUpgradePlanModalOpen,
    onUpgradePlanModalClose,
    showNotificationBanner,
    notificationBannerMessage,
    showCelebration,
  } = useSubscription()

  const location = useCleanlabLocation()
  const hideNav = location === 'Home' && !isAuthenticated

  return (
    <Box width="100%">
      {!hideNav && <TopNav colorModeSwitcherDisabled={colorModeSwitcherDisabled} nav={nav} />}
      {showCelebration && <Confetti width={window.innerWidth} />}
      {showNotificationBanner && <NotificationBanner message={notificationBannerMessage} />}
      {isAuthenticated && !IS_VPC_ENVIRONMENT && (
        <>
          <SalesModal isOpen={isSalesModalOpen} onClose={onSalesModalClose} />
          <UpgradePlanModal isOpen={isUpgradePlanModalOpen} onClose={onUpgradePlanModalClose} />
        </>
      )}
      <Box
        as="main"
        position="relative"
        fontSize="xl"
        w="100%"
        minH={`calc(100vh - ${showNotificationBanner ? '116px' : '64px'} - 35px)`}
        mx="auto"
        pt={hideNav ? 0 : '20px'}
      >
        {myselfError ? (
          <ErrorPage
            title="Something went wrong getting your user details"
            description="Please log out and try again. If the problem persists please reach out to support@cleanlab.ai."
          />
        ) : (
          children
        )}
      </Box>
    </Box>
  )
}

const Layout = (props: LayoutProps) => {
  const { nav, children, requiresLogin = true, colorModeSwitcherDisabled = false } = props
  const { isAuthenticated, isLoading, user, error } = useAuth()
  const { isLoading: isConfirmEmailLoading } = useConfirmEmail({})

  const {
    activated: isActivated,
    accountActivated,
    emailConfirmed,
    isLoading: isMyselfLoading,
  } = useMyself()
  const toast = useToast()
  const queryClient = useQueryClient()
  const { isLoading: isActivateAccountLoading } = useActivateAccountMutation({})

  useEffect(() => {
    if (accountActivated && !emailConfirmed) {
      const pollInterval = setInterval(() => {
        queryClient.invalidateQueries(queryKeys.users.myself())
      }, 5000)
      return () => clearInterval(pollInterval)
    }
  }, [accountActivated, emailConfirmed, queryClient])

  const { mutate: activationMutation, isLoading: isActivationLoading } = useActivateMutation({
    onSuccess: () => localStorage.removeItem('CleanlabSignupToken'),
    onError: () => {
      toast({
        ...defaultToastAlertProps,
        render: renderChakraToastAlert({
          heading: 'Failed to activate account',
          description: (
            <Text>
              Failed to activate account. Please try again or contact support at{' '}
              <Link href="mailto:support@cleanlab.ai" isExternal>
                support@cleanlab.ai
              </Link>
              .
            </Text>
          ),
          status: 'warning',
        }),
      })
    },
  })

  const signupToken = localStorage.getItem('CleanlabSignupToken')

  useEffect(() => {
    if (signupToken && isActivated && !(accountActivated || emailConfirmed)) {
      activationMutation(signupToken)
    } else if (signupToken) {
      queryClient.invalidateQueries(queryKeys.users.myself())
    }
  }, [accountActivated, activationMutation, emailConfirmed, isActivated, queryClient, signupToken])

  if (!requiresLogin) {
    return <LayoutWrapper nav={nav} children={children} />
  }

  if (
    isLoading ||
    isActivationLoading ||
    isMyselfLoading ||
    isConfirmEmailLoading ||
    isActivateAccountLoading
  ) {
    return <LayoutWrapper nav={nav} children={<Loading />} />
  }

  // Not authenticated -> Home (aka Landing)
  if (!isAuthenticated) {
    return <LayoutWrapper nav={nav} children={children} />
  }

  if (isActivated && accountActivated && emailConfirmed) {
    if (IS_PROD_ENV) {
      try {
        Sentry.setUser({ email: user?.email, username: user?.name, id: user?.sub })
      } catch (err) {
        Sentry.captureException('Could not set user info.')
      }
    }

    return (
      <LayoutWrapper
        nav={nav}
        children={children}
        colorModeSwitcherDisabled={colorModeSwitcherDisabled}
      />
    )
  } else {
    if (isActivated === null || error) {
      return <Landing />
    } else if (IS_VPC_ENVIRONMENT && !isActivated) {
      return <LayoutWrapper nav={nav} children={<AwaitingAdminInvitationPage />} />
    } else {
      return (
        <LayoutWrapper
          nav={nav}
          children={<ActivateFreeTrialPage />}
          colorModeSwitcherDisabled={colorModeSwitcherDisabled}
        />
      )
    }
  }
}

export default Layout
