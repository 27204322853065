import { Tabs } from '@chakra-ui/react'
import Layout from '@common/layout/Layout'
import Loading from '@common/layout/loading/Loading'
import TopNavTabs from '@common/layout/topNavTabs/TopNavTabs'
import { Button } from '@components/button/Button'
import { IconExternalLink } from '@components/icons'
import { useAuth } from '@hooks/useAuth'
import { useMyself } from '@services/user/queries'
import { IS_LOCAL_ENV } from '@utils/environmentVariables'
import { checkFeatureFlag } from '@utils/functions/checkFeatureFlag'
import { cn } from '@utils/tailwindUtils'
import { ComponentPropsWithoutRef, forwardRef, ReactNode, useEffect, useMemo } from 'react'
import { Link, useLocation, useNavigate, useSearchParams } from 'react-router-dom'

import Dashboard from '../dashboard/Dashboard'
import Landing from '../landing/Landing'

const HeaderTabsWrap = forwardRef<HTMLDivElement, ComponentPropsWithoutRef<'div'>>(
  ({ className, ...props }, ref) => {
    return <div ref={ref} className={cn('flex items-center gap-5', className)} {...props}></div>
  }
)

export const HomeLayout = ({ children }: { children: ReactNode }) => {
  return <Layout nav={<HomeTabs />}>{children}</Layout>
}

const useGetHomeTabIndex = () => {
  const location = useLocation()
  return location.pathname.startsWith('/tlm') ? 1 : 0
}

const HOME_TAB_NAMES = ['Dashboard']

const HomeTabs = () => {
  const { emailConfirmed } = useMyself()
  const tabIndex = useGetHomeTabIndex()
  const navigate = useNavigate()
  const tabNames = useMemo(() => (emailConfirmed ? HOME_TAB_NAMES : []), [emailConfirmed])

  return (
    <HeaderTabsWrap>
      <Tabs
        index={tabIndex}
        onChange={(index) => {
          switch (index) {
            case 0:
              navigate('/')
              return
          }
        }}
      >
        <TopNavTabs tabNames={tabNames} />
      </Tabs>
      <Button variant="secondary" size="small" aria-label="Go to TLM" asChild>
        <Link to="https://tlm.cleanlab.ai">
          <div className="flex items-center gap-4">
            <span>TLM</span> <IconExternalLink />
          </div>
        </Link>
      </Button>
      {checkFeatureFlag('IS_ADMIN') && (
        <Button variant="highContrast" size="small" aria-label="View admin panel" asChild>
          <Link to="/admin">Admin panel</Link>
        </Button>
      )}
      {IS_LOCAL_ENV && (
        <Button variant="secondary" size="small" aria-label="Set feature flags" asChild>
          <Link to="/flags">Feature flags</Link>
        </Button>
      )}
    </HeaderTabsWrap>
  )
}

const Home = () => {
  const { isAuthenticated, isLoading: isAuthLoading, error: authError } = useAuth()
  const { emailConfirmed, isLoading: isMyselfLoading } = useMyself()
  const [searchParams, setSearchParams] = useSearchParams()
  const navigate = useNavigate()
  const signupFromTLM = searchParams.get('signup_origin') === 'TLM'

  useEffect(() => {
    if (signupFromTLM && emailConfirmed) {
      searchParams.delete('signup_origin')
      setSearchParams(searchParams)
      navigate('/tlm')
    }
  }, [emailConfirmed, navigate, searchParams, setSearchParams, signupFromTLM])

  if (isAuthLoading || isMyselfLoading) {
    return <Loading />
  }
  if (!isAuthenticated || authError) {
    return <Landing />
  }
  return (
    <HomeLayout>
      <Dashboard />
    </HomeLayout>
  )
}

export default Home
