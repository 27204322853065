export interface GetFeatureFlagsRes {
  id: number
  feature_flag_id: string
  user_id: string
  email: string
  name: string
  timestamp: number
}

export interface FeatureFlags {
  id: number
  featureFlagId: string
  userId: string
  email: string
  name: string
  timestamp: number
}

export const queryKeys = {
  featureFlags: {
    all: () => ['featureFlags'] as const,
    allAvailable: () => ['availableFeatureFlags'] as const,
    self: () => [...queryKeys.featureFlags.all(), 'self'],
  },
}

export const FEATURE_FLAGS_AS_ARRAY: string[] = [
  'IS_ADMIN',
  'PYTHON_INFERENCE_API',
  'TRUSTWORTHY_LLM',
  'PII_ENABLED',
  'PII_METADATA_ENABLED',
  'INFORMAL_COLUMNS_ENABLED',
  'INFORMAL_METADATA_ENABLED',
  'TOXICITY_COLUMNS_ENABLED',
  'NONENGLISH_COLUMNS_ENABLED',
  'NONENGLISH_METADATA_ENABLED',
  'TEXT_SENTIMENT_COLUMNS_ENABLED',
  'BIAS_COLUMNS_ENABLED',
  'CLEANVISION_COLUMNS_ENABLED',
  'DATA_LABELING_TOUR',
  'AESTHETIC_COLUMNS_ENABLED',
  'UI_AESTHETIC_COLUMNS_ENABLED',
  'NSFW_COLUMNS_ENABLED',
  'UI_NSFW_COLUMNS_ENABLED',
  'TEXT_OUTLIERS_METADATA_ENABLED',
  'ENRICHMENT_UI_ENABLED',
]
