export type EnvironmentType =
  | 'development'
  | 'staging'
  | 'local_staging'
  | 'production'
  | 'infra_dev'
  | 'test'
  | 'hosted_dev'
  | 'vpc'
export const ENV_SETTINGS: EnvironmentType = import.meta.env
  .REACT_APP_ENV_SETTINGS as EnvironmentType
export const IS_PROD_ENV = import.meta.env.REACT_APP_ENV_SETTINGS === 'production'
export const IS_LOCAL_ENV =
  import.meta.env.REACT_APP_ENV_SETTINGS === 'local_staging' ||
  import.meta.env.REACT_APP_ENV_SETTINGS === 'development'

// Allows us to dynamically determine if we're running in a puppeteer environment
const urlParams = new URLSearchParams(window.location.search)
export const RUNNING_IN_PUPPETEER = urlParams.get('runningInPuppeteer') === 'true'

export const REACT_APP_CLEANLAB_API_URL =
  RUNNING_IN_PUPPETEER && ENV_SETTINGS === 'development'
    ? 'https://nginx:8301'
    : import.meta.env.REACT_APP_CLEANLAB_API_URL ||
      (ENV_SETTINGS === 'local_staging'
        ? 'https://api.staging-bc26qf4m.cleanlab.ai'
        : 'https://backend:5000')

// We use the test mode publishable key for testing purposes in our client-side code
// When we're ready to launch our app, we can switch production to live mode publishable key
// https://stripe.com/docs/keys#obtain-api-keys
export const STRIPE_PUBLIC_KEY =
  import.meta.env.REACT_APP_STRIPE_PUBLIC_KEY ||
  (ENV_SETTINGS === 'local_staging'
    ? 'pk_test_51MEN0rAVVBFD2GN2dygczdwc1fVclKVeIDhzRVpuiBHVR3PvwZSTwsHeuP0k7vmH5EBA80hxOh8m33hMAthHEBqk00Ba2CKJG8'
    : 'pk_test_51Ln8kREoiGD1MD217zkrFdQoGXcYN1MfvRSAam5UAZJwDMcSh3bm6VpTWUdi2sNRC5UTJNOqWgTNVxcrEhp2fQj200GQ8ufgDh')
export const FEATURE_FLAG_COOKIE_NAME =
  import.meta.env.REACT_APP_STUDIO_FF_COOKIE_NAME || 'cleanlab-ff'

export const INTERCOM_APP_ID = import.meta.env.REACT_APP_INTERCOM_APP_ID || 'y6k5m9ye'

export const PUBLIC_POSTHOG_KEY =
  import.meta.env.REACT_APP_PUBLIC_POSTHOG_KEY || 'phc_IqHg4uTP2wPykNG21gDwDBqarxhTswDKPj9cIUxIuoe'

export const PUBLIC_POSTHOG_HOST =
  import.meta.env.REACT_APP_PUBLIC_POSTHOG_HOST || 'https://app.posthog.com'

export const REACT_APP_CLEANLAB_HEADLESS_BROWSER_API_URL =
  ENV_SETTINGS === 'development' ? 'http://localhost:4000' : REACT_APP_CLEANLAB_API_URL

export const IS_VPC_ENVIRONMENT = import.meta.env.REACT_APP_IS_VPC_ENVIRONMENT === 'true' || false

// OAuth 2 / OpenID Connect settings, only used for VPC
export const OAUTH_CLIENT_ID = import.meta.env.REACT_APP_OAUTH_CLIENT_ID
export const OAUTH_AUTHORIZATION_ENDPOINT = import.meta.env.REACT_APP_OAUTH_AUTHORIZATION_ENDPOINT
export const OAUTH_TOKEN_ENDPOINT = import.meta.env.REACT_APP_OAUTH_TOKEN_ENDPOINT
export const OAUTH_SCOPE = import.meta.env.REACT_APP_OAUTH_SCOPE
export const AUTH_MODE_OAUTH = OAUTH_CLIENT_ID && OAUTH_CLIENT_ID.length > 0
